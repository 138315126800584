import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-2" }
const _hoisted_3 = { class: "col-xl-2" }
const _hoisted_4 = { class: "col-xl-2" }
const _hoisted_5 = { class: "col-xl-2" }
const _hoisted_6 = { class: "col-xl-2" }
const _hoisted_7 = { class: "col-xl-2" }
const _hoisted_8 = { class: "row g-5 g-xl-8" }
const _hoisted_9 = { class: "col-xl-6" }
const _hoisted_10 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsWidget5 = _resolveComponent("StatisticsWidget5")!
  const _component_StatisticsWidget5_1 = _resolveComponent("StatisticsWidget5_1")!
  const _component_MonthlyEventsStatistics = _resolveComponent("MonthlyEventsStatistics")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": `${_ctx.publicPath}assets/svg/users.svg`,
          color: "white",
          "icon-color": "primary",
          title: _ctx.dashboard.total_users_count,
          description: "Total Users",
          linkName: "adminUsers"
        }, null, 8, ["svg-icon", "title"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": `${_ctx.publicPath}assets/svg/new-user.svg`,
          color: "white",
          "icon-color": "white",
          title: _ctx.dashboard.new_users_count,
          description: "New Users",
          linkName: "adminUsers",
          linkQuery: { latest: true }
        }, null, 8, ["svg-icon", "title"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": `${_ctx.publicPath}assets/svg/calendar.svg`,
          color: "warning",
          "icon-color": "white",
          title: _ctx.dashboard.public_events_count,
          description: "Public Events",
          linkName: "adminEvents",
          linkQuery: { visibility: 'public' }
        }, null, 8, ["svg-icon", "title"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": `${_ctx.publicPath}assets/svg/calendar.svg`,
          color: "info",
          "icon-color": "white",
          title: _ctx.dashboard.private_events_count,
          description: "Private Events",
          linkName: "adminEvents",
          linkQuery: { visibility: 'private' }
        }, null, 8, ["svg-icon", "title"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_StatisticsWidget5_1, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": `${_ctx.publicPath}assets/svg/users.svg`,
          color: "white",
          "icon-color": "white",
          counts: _ctx.dashboard.active_users_counts,
          description: "Active Users",
          linkName: "adminUsers"
        }, null, 8, ["svg-icon", "counts"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_StatisticsWidget5_1, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": `${_ctx.publicPath}assets/svg/users.svg`,
          color: "white",
          "icon-color": "white",
          counts: _ctx.dashboard.returning_users_counts,
          description: "Returning Users",
          linkName: "adminUsers"
        }, null, 8, ["svg-icon", "counts"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_MonthlyEventsStatistics)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_MonthlyEventsStatistics)
      ])
    ])
  ], 64))
}