
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import StatisticsWidget5_1 from "@/components/widgets/statsistics/Widget5_1.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import MonthlyEventsStatistics from "@/components/admin/MonthlyEventsStatistics.vue";

export default defineComponent({
  name: "AdminDashboard",
  components: {
    StatisticsWidget5,
    StatisticsWidget5_1,
    MonthlyEventsStatistics,
  },
  setup() {
    const store = useStore();

    let dashboard = computed(() => {
      return store.getters[Modules.DASHBOARD + "getDashboard"];
    });

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });

    fetchRecords();

    function fetchRecords() {
      store.dispatch(Modules.DASHBOARD + Actions.FETCH_DASHBOARD);
    }

    return {
      dashboard,
      publicPath: process.env.BASE_URL,
    };
  },
});
