import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: {},
    class: _normalizeClass([[_ctx.widgetClasses, `bg-${_ctx.color}`], "card hoverable"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass([`svg-icon-${_ctx.iconColor}`, "svg-icon svg-icon-3x mx-n1"])
        }, [
          _createVNode(_component_inline_svg, { src: _ctx.svgIcon }, null, 8, ["src"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-2 mb-2 mt-5"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.counts[_ctx.selectedItemKey]) + " / ", 1),
          _withDirectives(_createElementVNode("select", {
            class: "",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItemKey) = $event)),
            style: {"font-size":"11px"}
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsDDs, (itemValue, itemKey) => {
              return (_openBlock(), _createElementBlock("option", {
                value: itemKey,
                key: itemKey
              }, _toDisplayString(itemValue), 9, _hoisted_2))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.selectedItemKey]
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bold fs-7"])
        }, _toDisplayString(_ctx.description), 3)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}