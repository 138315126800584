import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: _ctx.linkName, query: _ctx.linkQuery },
    class: _normalizeClass([[_ctx.widgetClasses, `bg-${_ctx.color}`], "card hoverable"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass([`svg-icon-${_ctx.iconColor}`, "svg-icon svg-icon-3x mx-n1"])
        }, [
          _createVNode(_component_inline_svg, { src: _ctx.svgIcon }, null, 8, ["src"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-2 mb-2 mt-5"])
        }, _toDisplayString(_ctx.title), 3),
        _createElementVNode("div", {
          class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bold fs-7"])
        }, _toDisplayString(_ctx.description), 3)
      ])
    ]),
    _: 1
  }, 8, ["to", "class"]))
}