
import { defineComponent, toRefs, ref, watch, onMounted } from "vue";

export default defineComponent({
  name: "kt-widget-5-1",
  props: {
    widgetClasses: String,
    color: String,
    iconColor: String,
    svgIcon: String,
    title: String,
    description: String,
    linkName: String,
    linkQuery: String,
    counts: Object,
  },
  components: {},
  setup(props) {
    // const { counts: allCounts } = toRefs(props);

    let itemsDDs = ref({
      day: "last 1 day",
      month: "last 1 month",
      quarter: "last 3 months",
      half: "last 6 months",
      year: "last 1 year",
    });
    let selectedItemKey = ref("");

    watch(selectedItemKey, (value) => {
      console.log(value);
    });

    onMounted(() => {
      selectedItemKey.value = "day";
    });

    return { selectedItemKey, itemsDDs };
  },
});
